import eventClientApi from "@/api-client/event-client";
import { cleanObject } from "@/lib/helper";
import { toast } from "sonner";
import { EVENT_JOIN_STATUS } from "@key.ai/constants";

export const fetchProxyEventData = async (eventUrl: string) => {
  try {
    const { data } = await eventClientApi.get(`api/v2/proxy/events/${eventUrl}`).json<any>();
    return data;
  } catch (error: any) {
    toast.error(error?.message || "Failed to fetch event data");
    throw error;
  }
};

export const getQuestionnaire = async (eventId: string) => {
  try {
    const resp = await eventClientApi.get(`api/v1/events/${eventId}/questions`).json<any>();
    return resp?.data
      ? { status: true, data: resp.data, submitted: resp?.response_given }
      : { status: false };
  } catch (error: any) {
    toast.error("Error occurred while fetching questionnaire");
    return { status: false };
  }
};

export const checkIfPaidTickets = (tickets: any) => {
  return tickets.some((ticket: any) => ticket?.type === "paid");
};

export const submitQuestionnaireResponses = async (eventId: string, queResponses: any[]) => {
  try {
    if (queResponses.length > 0) {
      const payload = queResponses.filter((item: any) =>
        item.response.response_text.some((text: string) => text.trim() !== "")
      );
      await eventClientApi.post(`api/v1/events/${eventId}/questionnaire-responses`, {
        json: { question_responses: [...payload] }
      });
    }
    return { success: true };
  } catch (error: any) {
    toast.error("Error occurred while submitting questionnaire responses");
    return { success: false };
  }
};

export const createParticipation = async (userId: string, eventId: string, eventData: any) => {
  const data = {
    session_participation: [
      { event_session_id: eventData?.event_sessions[0]?.id, status: EVENT_JOIN_STATUS.GOING }
    ],
    ticket: eventData?.event?.monetary_type_details[0]?.id
  };

  try {
    const passcode = sessionStorage.getItem(`${eventData?.event_url}_${userId}`) ?? "";
    const createResp = await eventClientApi
      .post(`api/v2/users/${userId}/events/${eventId}/participations`, {
        searchParams: cleanObject({ passcode }),
        json: { ...data }
      })
      .json<any>();

    if (createResp?.message) {
      toast.success(createResp.message);
    }
    return { success: true, message: createResp.message };
  } catch (error: any) {
    toast.error(error?.message || "Failed to register for event");
    return { success: false };
  }
};

export const declineEventRegistration = async (eventId: string, participationId: string) => {
  try {
    const rsp = await eventClientApi
      .delete(`api/v1/events/${eventId}/participations/${participationId}`)
      .json<any>();
    toast.success(rsp.message || "Event registration declined");
  } catch (error) {
    toast.error(error.message || "Something went wrong");
  }
};
