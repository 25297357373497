"use client";

import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

import { cn } from "@/lib/utils";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    size?: "small" | "medium" | "large" | "xsmall";
    variant?: "rounded" | "square";
  }
>(({ className, size = "small", variant = "square", ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "flex items-center justify-center peer h-4 w-4 shrink-0 border-1 border-[#E6E7EA] ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30 data-[state=checked]:bg-white data-[state=checked]:text-black data-[state=checked]:border-black hover:border-black hover:bg-white",
      {
        "h-3 w-3 rounded-5": size === "xsmall",
        "h-4 w-4 rounded-[5px]": size === "small",
        "h-5 w-5 rounded-7": size === "medium",
        "h-6 w-6 rounded-8": size === "large"
      },
      {
        "h-6 w-6 rounded-full  data-[state=checked]:border-black data-[state=checked]:bg-black data-[state=checked]:text-color-contrast hover:border-black data-[state=checked]:border-border-black":
          variant === "rounded",
        "data-[state=checked]:border-black data-[state=checked]:bg-black data-[state=checked]:text-color-contrast hover:border-black ":
          variant === "square"
      },
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn("")}>
      <Check
        className={cn(
          "h-4 w-4 font-bold stroke-3 p-[1px] ",
          {
            "h-3 w-3": size === "xsmall",
            "h-4 w-3": size === "small",
            "h-5 w-5": size === "medium",
            "h-6 w-6": size === "large"
          },
          {
            "": variant === "rounded",
            "": variant === "square"
          }
        )}
      />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
