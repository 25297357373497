import React, { useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "sonner";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/stores/use-auth-store";
import eventClientApi from "@/api-client/event-client";
import { cleanObject } from "@/lib/helper";

interface BpEventQuestionnaireProps {
  handleCancel: () => void;
  eventData: any;
  handleRegisterEvent: () => void;
  open: boolean;
  eventId?: string;
  eventToken: string;
  eventUser: any;
  setEventToken: React.Dispatch<any>;
  setEventUser: React.Dispatch<React.SetStateAction<any>>;
}

const formSchema = yup.object({
  firstname: yup.string().trim().required("First name is required"),
  lastname: yup.string().trim().required("Last name is required"),
  email: yup.string().trim().email("Invalid email").required("Email is required")
});

const OneClickRegisterForm: React.FC<BpEventQuestionnaireProps> = ({
  handleCancel,
  open,
  eventData,
  handleRegisterEvent,
  eventToken,
  eventUser,
  setEventToken,
  setEventUser
}) => {
  useEffect(() => {
    if (eventUser) {
      formik.setValues({
        firstname: eventUser?.firstname || "",
        lastname: eventUser?.lastname || "",
        email: eventUser?.email || ""
      });
      if (!eventUser?.isNewUser) {
        handleRegisterEvent();
        handleCancel();
      }
    }
  }, [eventUser]);

  const CheckIfNewUser = async () => {
    try {
      const response = await eventClientApi
        .get(`api/v1/common/${eventData?.id}/validate-token`, {
          searchParams: cleanObject({
            ...(eventToken ? { token: eventToken } : {})
          })
        })
        .json<any>();
      setEventUser(response?.data);
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: ""
    },
    validationSchema: formSchema,
    onSubmit: async (submitValues) => {
      const trimmedValues = {
        firstname: submitValues.firstname.trim(),
        lastname: submitValues.lastname.trim()
      };

      try {
        const resp = await eventClientApi
          .post(`api/v1/common/profile?token=${eventToken} `, { json: trimmedValues })
          .json<any>();
        toast.success(resp.message);
        await CheckIfNewUser();
      } catch (error) {
        toast.error(error.message);
      }
    }
  });

  return (
    <Dialog open={open} onOpenChange={handleCancel}>
      <DialogContent className="max-h-[610px] md:max-h-[600px] max-w-full-50px w-[500px] overflow-y-auto">
        <DialogHeader className="sticky">
          <DialogTitle>RSVP</DialogTitle>
        </DialogHeader>
        <div>
          <form onSubmit={formik.handleSubmit} className="space-y-4">
            <div>
              <label className="text-sm font-medium">First name *</label>
              <Input
                name="firstname"
                placeholder="First name"
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.firstname && formik.errors.firstname)}
                errorMessage={(formik.touched.firstname && formik.errors.firstname) || ""}
              />
            </div>

            <div>
              <label className="text-sm font-medium">Last name *</label>
              <Input
                name="lastname"
                placeholder="Last name"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.lastname && formik.errors.lastname)}
                errorMessage={(formik.touched.lastname && formik.errors.lastname) || ""}
              />
            </div>

            <div>
              <label className="text-sm font-medium">Email *</label>
              <Input
                type="email"
                name="email"
                disabled
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.email && formik.errors.email)}
                errorMessage={(formik.touched.email && formik.errors.email) || ""}
              />
            </div>

            <Button type="submit" className="w-full mt-4">
              Request to join
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OneClickRegisterForm;
